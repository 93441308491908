.App {
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
input[name='datumRjesenja'] {
  padding: 10px;
  margin-bottom: 0 !important;
}
button {
  outline: 0;
}
button[class='MuiButtonBase-root MuiIconButton-root'] {
  width: 100% !important;
  outline: 0 !important ;
  &:focus {
    outline: 0 !important;
    width: 100% !important;
  }
}
button[class='MuiButtonBase-root MuiIconButton-root Mui-focusVisible'] {
  width: 100% !important;
  outline: 0 !important ;
}
